.typeMiniformsBarsAccionsItemsMinimal {
  background-color: #ffffff;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
    0px 1px 2px rgb(100 116 139 / 10%);
  overflow: hidden;
  -webkit-align-items: lef;
  -webkit-box-align: lef;
  -ms-flex-align: lef;
  align-items: lef;
  display: inline-block;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  margin: 5px;
  padding: 3px;
  min-width: 200px;
  width: 200px;
  border-radius: 3px;
}
.colorDrark {
  color: #121828;
}
.btn-tool-small {
  background: #7d0ee6 !important;
  display: inline-block !important;
  padding: 5px 5px 5px 5px !important;
  border-radius: 4px !important;
  color: #f8f8f8 !important;
  font-size: 12px;
  font-weight: 300;
  margin: 5px !important;
  line-height: 1;
  cursor: pointer;
}

.btn-tool-small-onlyColor {
  background: #7d0ee6 !important;
}
.alerta-asignar {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 2em;
}
